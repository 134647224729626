<template>
  <div class="admin-stats-visitor">
    <table class="table">
      <thead>
      <tr>
        <th>번호</th>
        <th>일자</th>
        <th>접속자 수</th>
        <th>최종 집계 시각</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(d, idx) in state.visitors.data" :key="d.date">
        <td>{{ state.visitors.data.length - idx }}</td>
        <td>{{ d.date }}</td>
        <td>{{ lib.getNumberFormat(d.count) }}</td>
        <td>{{ d.time }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import mixin from "@/scripts/mixin";
import {reactive} from "@vue/composition-api";
import http from "../../../scripts/http";
import lib from "../../../scripts/lib";

function Component(initialize) {
  this.name = "pageAdminStatsUtm";
  this.initialize = initialize;
}

export default {
  computed: {
    lib() {
      return lib;
    }
  },
  components: {},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      init();
    });

    const state = reactive({
      visitors: {
        loaded: false,
        data: [],
      }
    });

    const init = () => {
      state.visitors.loaded = false;
      http.get("/api/admin/stats/visitors").then(({data}) => {
        state.visitors.loaded = true;
        state.visitors.data = data.body;
      });
    };

    return {
      component,
      state,
    };
  }
};
</script>

<style lang="scss" scoped>
.admin-stats-visitor {
  table {
    th {
      background: #f7f7f7;
    }
  }
}
</style>